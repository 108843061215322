const web3 = require('web3');
const BN = web3.utils.BN;

// measured gas fee for Hodl `deposit` and `collect`
const GAS_FEE = new BN(135000);
const E18 = (new BN(10).pow(new BN(18)));
const E16 = (new BN(10).pow(new BN(16)));

function contractCodeStrEmpty(codeStr) {
  if (codeStr === ''   || codeStr === '0x' || codeStr === '0x0') {
    return true;
  } else {
    return false;
  }
}

module.exports = {
  GAS_FEE,
  E18,
  E16,
  contractCodeStrEmpty
}
