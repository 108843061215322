import * as Sentry from '@sentry/browser';
import { globalHistory } from "@reach/router"

Sentry.init({
  dsn: 'https://4833a0a448424fa08077ba9aa935d11c@o262383.ingest.sentry.io/5237958'
});

// set `testingSentry` to true to always report errors
const testingSentry = false;

// 108: thunder-mainnet
// 18: thunder-testnet and thudner-staging
const thunderNetworks = new Set(['108', '18']);

const localhostNames = new Set(['localhost', '127.0.0.1', '0.0.0.0', '[::1]']);

function shouldReportError(hostname, networkId) {
  if (testingSentry) {
    return true;
  }
  if (localhostNames.has(hostname)) {
    return false;
  }
  if (!thunderNetworks.has(String(networkId))) {
    return false;
  }
  return true;
}

// networkId is the return value of the Ethereum JSON-RPC `net_version` method
// e.g. `web3.eth.net.getId()`
export function captureMessage(networkId, ...args) {
  if (!shouldReportError(globalHistory.location.hostname, networkId)) {
    return;
  }
  Sentry.captureMessage.apply(null, args);
}

export function captureException(networkId, ...args) {
  if (!shouldReportError(globalHistory.location.hostname, networkId)) {
    return;
  }
  Sentry.captureException.apply(null, args);
}

export function captureEvent(networkId, ...args) {
  if (!shouldReportError(globalHistory.location.hostname, networkId)) {
    return;
  }
  Sentry.captureEvent.apply(null, args);
}

export function setAddress(address) {
  Sentry.configureScope(function(scope) {
    scope.setUser({
      "address": address
    });
    scope.setTag("wallet.address", address)
  });
}
