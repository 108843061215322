const Choice = Object.freeze({
  REAL: Symbol('Hodl'),
  FAKE_TIME: Symbol('HodlWithFakeTime'),
});

async function getContractData(activeEnv, forceChoice) {
  let contractName, contractDir, contractData;

  if (forceChoice === Choice.REAL) {
    contractName = 'Hodl';
  } else if (forceChoice === Choice.FAKE_TIME) {
    contractName = 'HodlWithFakeTime';
  } else if (activeEnv === 'production') {
    contractName = 'Hodl';
  } else {
    contractName = 'HodlWithFakeTime';
  }

  if (activeEnv === 'production') {
    contractDir = 'contracts-deployed-to-production';
  } else if (activeEnv === 'staging') {
    contractDir = 'contracts-deployed-to-production';
  } else {
    contractDir = 'contracts';
  }

  contractData = require(`../${contractDir}/${contractName}.json`);
  console.log(`HodlContractImporter: using contract ${contractDir}/${contractName}`);
  return contractData;
}

function contractDataAddresses(contractData) {
  const out = [];
  let n = contractData.networks;
  for (const i in n) {
    out.push([i, n[i].address]);
  }
  return out;
}

module.exports = { getContractData, Choice, contractDataAddresses };
