const web3 = require('web3');
const BN = web3.utils.BN;
// -> 'ongoing' | 'matured' | 'closed'
function depositRecordStateStr(dr, blockTime) {
  const daysPassed = blockTime.sub(dr.startTime).div(SECS_PER_DAY);
  if (daysPassed.lt(dr.lockupPeriod)) {
    return 'ongoing';
  } else {
    return 'matured';
  }
}

function hasAutoDepositRecord(blockTime, deposits) {
  for (let i = 0; i < deposits.length; i++) {
    const d = deposits[i];
    const daysPassed = blockTime.sub(d.startTime).div(SECS_PER_DAY);
    if (daysPassed.gt(d.lockupPeriod.add(d.autoDepositDuration))) {
      return true;
    }
  }
  return false;
}

// unixTime returns seconds since epoch
function unixTime() {
  return new BN(Math.floor(new Date() / 1000));
}

function padZerosLeft(s, l) {
  if (s.length < l) {
    return '0'.repeat(l - s.length) + s;
  } else {
    return s;
  }
}

// -> '2019.03.05'
function unixTimeToDateStr(secs) {
  if (secs.isNeg(secs)) {
    return '(Negative)';
  }
  const d = new Date(secs * 1000);
  function P2(v) {
    return padZerosLeft(v.toString(), 2);
  }
  // Date.prototype.getMonth() is zero based
  return `${padZerosLeft(d.getFullYear().toString(), 4)}.${P2(d.getMonth()+1)}.${P2(d.getDate())}`;
}

// -> '2019.03.05.01:00'
function unixTimeToDateTimeStr(secs) {
  if (secs.isNeg(secs)) {
    return '(Negative)';
  }
  const d = new Date(secs * 1000);
  function P2(v) {
    return padZerosLeft(v.toString(), 2);
  }
  return `${unixTimeToDateStr(secs)}.${P2(d.getHours())}:${P2(d.getMinutes())}`;
}

const SECS_PER_DAY = new BN(24 * 60 * 60);

class BlockTimeWentBackwardsError extends Error {
  constructor(message) {
    super(message);
    this.name = 'BlockTimeWentBackwardsError';
    this.message = message;
  }
}

const E16 = new BN(10).pow(new BN(16));
const E18 = new BN(10).pow(new BN(18));

function numericStrDot2F(s) {
  if (s.length == 0) {
    return '0.00';
  } else if (s.length == 1) {
    return `0.0${s}`;
  } else if (s.length == 2) {
    return `0.${s}`;
  } else {
    return `${s.slice(0, s.length-2)}.${s.slice(s.length-2, s.length)}`;
  }
}

// -> TT for UI display: String
function TT(x) {
  const s = x.div(E16).toString();
  const s2f = numericStrDot2F(s);
  // add comma every 3 digits
  const l = s2f.length;
  const [head, tail] = [s2f.slice(0, l-3), s2f.slice(l-3, l)]; // 3 === '.NN'.length
  const commaL = 3;  // one comma every X digits
  const i0 = head.length % commaL;
  const out = new Array();
  if (i0 !== 0) {
    out.push(head.slice(0, i0));
  }
  for (let i = i0; i < head.length; i += commaL) {
    out.push(head.slice(i, i + commaL));
  }
  return out.join(',') + tail;
}

function sTT(x) {
  if (x === undefined) {
    return '';
  }
  if (typeof x === 'number') {
    throw Error('sTT: input is number type');
  } else if (typeof x === 'string') {
    x = new BN(x);
  }
  return TT(x);
}

function roundedTT(x) {
  const s = x.divRound(E18);
  return s.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function interestRateStr(m, d) {
  console.log(m, d);

  return `${numericStrDot2F(m.mul(new BN(10000)).div(d).toString())}%`;
}

function percentageStr(ratio) {
  if (ratio === undefined) {
    return '0.00%';
  }
  const p = ratio * 100;
  let s = p.toString();
  let i = s.lastIndexOf('.');
  if (i === -1) {
    s = s + '.00';
    i = s.length - 3;
  }
  return `${s.slice(0, i+3)}%`;
}

function depositRecordNextCollectibleInSeconds(dr, blockTime) {
  // assume collectible in every 24-hour period since dr.startTime
  return SECS_PER_DAY.sub(blockTime.sub(dr.startTime).mod(SECS_PER_DAY));
}

function advanceDays(time, days) {
  return SECS_PER_DAY.mul(days).add(time);
}

function secondsToHoursMinsSecs(t) {
  const Sixty = new BN(60);
  const secs = t.mod(Sixty);
  const t1 = t.div(Sixty);
  const mins = t1.mod(Sixty);
  const hours = t1.div(Sixty);
  return [hours, mins, secs];
}

function secondsToHhMmSsStr(t) {
  const [h, m, s] = secondsToHoursMinsSecs(t);
  function P2(n) {
    return padZerosLeft(n.toString(), 2);
  }
  return `${P2(h)}:${P2(m)}:${P2(s)}`;
}

module.exports = {
  BlockTimeWentBackwardsError,
  unixTime,
  SECS_PER_DAY,
  depositRecordStateStr,
  hasAutoDepositRecord,
  padZerosLeft,
  unixTimeToDateStr,
  unixTimeToDateTimeStr,
  percentageStr,
  TT,
  sTT,
  interestRateStr,
  depositRecordNextCollectibleInSeconds,
  secondsToHhMmSsStr,
  roundedTT,
  advanceDays,
}
