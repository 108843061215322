const web3 = require('web3');
const BN = web3.utils.BN;

class DepositOption {
  constructor(principal, aprNumer, aprDenom, lockupPeriod, withdrawInterval, refAprNumer, refAprDenom, refLockupPeriod, refWithdrawInterval, autoDepositDuration, remainingSeats) {
    this.principal = new BN(principal);
    this.aprNumer = new BN(aprNumer);
    this.aprDenom = new BN(aprDenom);
    this.lockupPeriod = new BN(lockupPeriod);
    this.withdrawInterval = new BN(withdrawInterval);
    this.refAprNumer = new BN(refAprNumer);
    this.refAprDenom = new BN(refAprDenom);
    this.refLockupPeriod = new BN(refLockupPeriod);
    this.refWithdrawInterval = new BN(refWithdrawInterval);
    this.autoDepositDuration = new BN(autoDepositDuration);
    this.remainingSeats = new BN(remainingSeats);
  }

  toString() {
    let str = 'DepositOption:\n';
    str += '\tprincipal: ' + this.principal.toString() + '\n';
    str += '\tapr: ' + this.aprNumer.toString() + '/' + this.aprDenom.toString() + '\n';
    str += '\tlockupPeriod: ' + this.lockupPeriod.toString() + '\n';
    str += '\twithdrawInterval: ' + this.withdrawInterval.toString() + '\n';
    str += '\trefapr: ' + this.refAprNumer.toString() + '/' + this.refAprDenom.toString() + '\n';
    str += '\trefLockupPeriod: ' + this.refLockupPeriod.toString() + '\n';
    str += '\trefWithdrawInterval: ' + this.refWithdrawInterval.toString() + '\n';
    str += '\tautoDepositDuration: ' + this.autoDepositDuration.toString() + '\n';
    str += '\tremainingSeats: ' + this.remainingSeats.toString() + '\n';
    return str;
  }
}

function toDepositOption(list) {
  return new DepositOption(list[0], list[1], list[2], list[3], list[4], list[5], list[6], list[7], list[8], list[9], list[10]);
}

async function addDepositOption(contract, depositOption, web3Option, estimateGas) {
  const method = contract.methods.addDepositOption(
    depositOption.principal.toString(),
    depositOption.aprNumer.toString(),
    depositOption.aprDenom.toString(),
    depositOption.lockupPeriod.toString(),
    depositOption.withdrawInterval.toString(),
    depositOption.refAprNumer.toString(),
    depositOption.refAprDenom.toString(),
    depositOption.refLockupPeriod.toString(),
    depositOption.refWithdrawInterval.toString(),
    depositOption.autoDepositDuration.toString(),
    depositOption.remainingSeats.toString()
  );
  if (estimateGas) {
    web3Option.gas = await method.estimateGas(web3Option);
  }
  return method.send(web3Option);
}

async function setDepositOption(contract, idx, depositOption, web3Option, estimateGas) {
  const method = contract.methods.setDepositOption(
    idx.toString(),
    depositOption.aprNumer.toString(),
    depositOption.aprDenom.toString(),
    depositOption.lockupPeriod.toString(),
    depositOption.withdrawInterval.toString(),
    depositOption.refAprNumer.toString(),
    depositOption.refAprDenom.toString(),
    depositOption.refLockupPeriod.toString(),
    depositOption.refWithdrawInterval.toString(),
    depositOption.autoDepositDuration.toString(),
    depositOption.remainingSeats.toString()
  );
  if (estimateGas) {
    web3Option.gas = await method.estimateGas(web3Option);
  }
  return method.send(web3Option);
}

async function getDepositOption(contract, idx, web3Option) {
  const options = await getDepositOptions(contract, web3Option);
  return options[idx];
}

async function getDepositOptions(contract, web3Option) {
  return contract.methods
    .getDepositOptions()
    .call(web3Option)
    .then((depositOptions) => {
      return depositOptions.map(toDepositOption);
    });
}

async function getDepositOptionsCount(contract, web3Option) {
  return new BN(await contract.methods.getDepositOptionsCount().call(web3Option));
}

module.exports = {
  addDepositOption,
  setDepositOption,
  getDepositOption,
  getDepositOptions,
  getDepositOptionsCount,
};
