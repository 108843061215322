import { captureException, captureMessage, setAddress } from '../utils/sentry';
import React, { Component } from 'react';
import Layout from '../components/layout';
import PropTypes from "prop-types"
import { ic_coin, ic_logo, ig_card_success, ic_close, ic_forward, ic_important, ic_cross, ic_info } from '../utils/assets';
import { Tabs, Tab, Button, Modal } from 'react-bootstrap';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import HodlContractImporter from '../utils/HodlContractImporter';
import Web3Helper from "../utils/Web3Helper";
import Transform, { depositRecordStateStr, unixTimeToDateStr, TT, roundedTT, advanceDays, hasAutoDepositRecord } from '../utils/transform';
import {deposit, getDeposits, getReferrals, hasDeposited, NULL_ADDR} from '../utils/records';
import {getDepositOptions} from '../utils/deposit_options';
import { dict } from '../utils/language'
import HodlHelper, { contractCodeStrEmpty } from '../utils/hodl';
import Web3 from 'web3';
const BN = Web3.utils.BN;

// Set closing date
const closingDate = new Date("2022-07-15T03:00:00.000+00:00");
const now = new Date();
const isOpening = closingDate > now ? true : false

const stages = {
  HOME: 1,
  DEPOSIT: 2,
  DEPOSIT_DETAIL: 3,
  REFERRAL_DETAIL: 4,
  RULE: 5,
  ERROR: 0xdead,
}

function collectibleFromRecords(blockTime, deposits, referrals) {
  let collectables = depositCollectablePrincipals(blockTime, deposits);
  collectables.iadd(depositCollectableInterests(deposits));
  collectables.iadd(referralCollectables(referrals));
  return collectables;
}

function depositCollectablePrincipals(blockTime, deposits) {
  let collectables = new BN(0);
  for (const r of deposits) {
    if (depositRecordStateStr(r, blockTime) === 'matured') {
      collectables.iadd(r.principal);
    }
  }
  return collectables;
}

function depositCollectableInterests(deposits) {
  let collectables = new BN(0);
  for (const r of deposits) {
    collectables.iadd(r.collectables).isub(r.collected);
  }
  return collectables;
}

function referralCollectables(referrals) {
  let collectables = new BN(0);
  for (const r of referrals) {
    collectables.iadd(r.collectables).isub(r.collected);
  }
  return collectables;
}

function depositDurationStr(startTime, deposit) {
  const matureDate = advanceDays(startTime, deposit.lockupPeriod);
  return `${unixTimeToDateStr(startTime)}-${unixTimeToDateStr(matureDate)}`;
}

function depositAutoRenewDateStr(startTime, deposit) {
  const matureDate = advanceDays(startTime, deposit.lockupPeriod);
  const renewDate = advanceDays(matureDate, deposit.autoDepositDuration);
  return unixTimeToDateStr(renewDate);
}

function filterNullAddress(address) {
  if (address === NULL_ADDR) {
    return;
  }
  return address;
}

function shortAddress(address) {
  return `${address.substr(0, 12)}…${address.substr(-11)}`;
}

function validAddress(web3, address) {
  return address === '' || web3.utils.isAddress(address);
}

function calcPrincipalPlusInterestAtMaturity(dr) {
  if (dr === null) {
    return new BN(0);
  }
  return dr.principal.add(dr.principal.mul(dr.lockupPeriod).mul(dr.aprNumer).div(dr.aprDenom).div(new BN(365)));
}

function depositRecordPrincipalPlusInterestAtMaturity(dr) {
  return calcPrincipalPlusInterestAtMaturity(dr);
}

function principalPlusInterestAtMaturityMinusCollected(deposits, referrals) {
  const s = new BN(0);
  for (const r of deposits) {
    s.iadd(depositRecordPrincipalPlusInterestAtMaturity(r)).isub(r.collected);
  }
  for (const r of referrals) {
    s.iadd(referralReward(r).isub(r.collected));
  }
  return s;
}

function referralReward(r) {
  return r.principal.mul(r.lockupPeriod).mul(r.aprNumer).div(r.aprDenom).div(new BN(365));
}

function lockedPrincipalSum(deposits, blockTime) {
  const s = new BN(0);
  for (const r of deposits) {
    if (depositRecordStateStr(r, blockTime) === 'ongoing') {
      s.iadd(r.principal);
    }
  }
  return s;
}

function depositRecordLockedPrincipal(dr, blockTime) {
  if (depositRecordStateStr(dr, blockTime) === 'ongoing') {
    return dr.principal;
  }
  return new BN(0);
}

const browserLanguageToLang = (l) => {
  if (l.startsWith('zh')) {
    return 'zh_CN';
  }
  return 'en';
}

const isSameAddress = (a, b) => {
  if (typeof(a) !== typeof(b)) {
    return false
  }
  return a.toLowerCase() === b.toLowerCase()
}

class Hodl extends Component {
  constructor() {
    super();
    this.state = {
      stage: stages.HOME,
      depositIdx: null,
      referrerAddress: '',
      depositStep: null,
      inputPackage: null,
      referrer: '',
      agreeTos: false,
      agreeLock: false,
      web3: null,
      accounts: null,
      contract: null,
      blockTime: null,
      hasDeposited: false,
      deposits: [],
      referrals: [],
      maxTotalDeposits: null,
      collectible: new BN(0),
      activeKey: 'action',
      mask: false,
      collectAmount: null,
      language: 'zh_CN',
      browserLanguage: 'zh_CN',
      hasViewMaxDepositsDialog: false,
      showMaxDepositsDialog: false
    };
    this.handleTabSelect = this.handleTabSelect.bind(this);
    // DEBUG: remove
    /*
    window.E18 = E18;
    window.BN = BN;
    window.TT = TT;
    window.unixTimeToDateStr = unixTimeToDateStr;
    */
  }

  async componentDidMount() {
    // DEBUG: remove
    /*
    window.HODL_DEBUG = 0;
    window.myDebugMessages = [];
    */
    let networkId = null;
    try {
      // DEBUG: remove
      // window.Hodl = this;
      this.setBrowserLanguage();
      console.log('componentDidMount: process.env.NODE_ENV:', process.env.NODE_ENV);
      console.log('componentDidMount: process.env.GATSBY_ACTIVE_ENV:', process.env.GATSBY_ACTIVE_ENV);
      this.w3helper = new Web3Helper();
      const web3 = await this.w3helper.getWeb3();
      if (web3 === undefined) {
        const err = new Error('getWeb3 failed: Web3 undefined');
        captureException(networkId, err);
        throw(err);
      }
      const accounts = await this.w3helper.getAccounts();
      if (accounts.length > 0) {
        setAddress(accounts[0])
      }
      networkId = await this.w3helper.getNetworkId();
      this.networkId = networkId;
      console.log('networkId:', networkId);
      const contractData = await HodlContractImporter.getContractData(process.env.GATSBY_ACTIVE_ENV);
      // DEBUG: remove
      // window.contractData = contractData;
      const deployedNetwork = contractData.networks[networkId];
      if (deployedNetwork === undefined) {
        this.setState({web3, accounts, contractNotDeployed: true});
        captureMessage(networkId,
                      `Contract deployment record not found: networkId: ${networkId}, contractName: ${contractData.contractName}, ` +
                      `networks: ${JSON.stringify(HodlContractImporter.contractDataAddresses(contractData))}`);
        return;
      }
      const h0 = new web3.eth.Contract(
        contractData.abi,
        deployedNetwork.address);
      this.setState({ web3: web3, accounts: accounts,
                      contractData: contractData, contract: h0,
                      contractAddress: deployedNetwork.address },
      this.updateRecordsAndOptions);
    } catch (err) {
      if (this.w3helper.errIsUserRejectingTransaction(err)) {
        this.setState({userRejectedTransaction: true});
        return;
      } else if (this.w3helper.errIsNotUsingDAppBrowser(err)) {
        this.setState({notUsingDAppBrowser: true});
        return;
      }
      // window.myDebugMessages.push(`componentDidMount exception handler, err: ${err}`);
      console.error('exception during contract setup:', err);
      this.setState({ stage: stages.ERROR, errorMsg: 'contract setup', error: err });
      captureException(networkId, err);
    }
  }

  setBrowserLanguage() {
    const url = new URL(this.props.location.href);
    let l;
    if (typeof(url.searchParams.get('fb_locale')) === 'string') {
      // https://developers.facebook.com/blog/post/2013/11/11/605/
      l = url.searchParams.get('fb_locale');
    } else if (typeof window !== 'undefined' && typeof window.navigator.language !== 'undefined') {
      l = window.navigator.language;
    } else {
      l = 'zh_CN';
    }
    this.setState({browserLanguage: l, language: browserLanguageToLang(l)});
    // DEBUG: remove
    // window.myDebugMessages.push(`setBrowserLanguage: ${l}, lang: ${browserLanguageToLang(l)}`)
  }

  async updateRecordsAndOptions(stage) {
    const {web3, accounts, contractAddress, contract} = this.state;
    let blockTime, deposits, referrals, depositOptions, _hasDeposited;
    try {
      const promises = [];
      promises.push(getDeposits(contract, accounts[0]));
      promises.push(getDepositOptions(contract));
      promises.push(getReferrals(contract, accounts[0]));
      promises.push(hasDeposited(contract, accounts[0]));
      const values = await Promise.all(promises);
      [blockTime, deposits] = values[0];
      depositOptions = values[1];
      [, referrals] = values[2];
      _hasDeposited = values[3];
    } catch(err) {
      if (this.w3helper.errIsUserRejectingTransaction(err)) {
        this.setState({userRejectedTransaction: true});
        return;
      } else if (contractAddress) {
        const contractCode = await web3.eth.getCode(contractAddress);
        if (contractCodeStrEmpty(contractCode)) {
          this.setState({contractNotDeployed: true});
          captureMessage(this.networkId, `Contract code empty: address: ${contractAddress}, networkId: ${this.networkId}`);
          return;
        } // FIXME: compare code hash with value in contract.jSON
      }
      throw err; // unhandled exceptions would be logged to sentry.io
    }
    const s = {blockTime: blockTime, deposits: deposits, referrals: referrals, depositOptions: depositOptions, collectible: collectibleFromRecords(blockTime, deposits, referrals), hasDeposited: _hasDeposited}
    if (stage !== undefined) {
      s.stage = stage;
    }
    this.setState(s);
  }

  async updateAccountBalance() {
    const {web3} = this.state;
    const account = this.state.accounts[0];
    let balance;
    try {
      balance = await web3.eth.getBalance(account);
    } catch(err) {
      if (this.w3helper.errIsUserRejectingTransaction(err)) {
        this.setState({userRejectedTransaction: true});
        return;
      }
      console.log(`getBalance(${account}) raised exception: `, err);
      this.setState({ stage: stages.ERROR, errorMsg: 'updateAccountBalance', error: err });
      captureException(this.networkId, err);
      return;
    }
    this.setState({accountBalance: new BN(balance), stage: stages.DEPOSIT, depositStep: 1});
  }

  renderUserError(msg) {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div id='home'>
          {this.renderHomeHeader()}
          <div className='home-content'>
            <div className='user-error'>{msg}</div>
          </div>
        </div>
      </Layout>
    );
  }

  renderHubDownload() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div id='home'>
          {this.renderHomeHeader()}
          <div className='home-content'>
            <div className='user-error'>
              {this.getWord('useDAppBrowser')}
              <div className='buttons'>
                <a href={this.getWord('downloadHubLink')}>
                  <Button
                    variant="hodl-primary"
                  >
                    {this.getWord('downloadHub')}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  render() {
    // DEBUG: remove
    /*
    if (window.HODL_DEBUG) {
      return this.renderDebug();
    }
    */
    if (this.state.contractNotDeployed) {
      return this.renderUserError(this.getWord('wrongNetwork'));
    } else if (this.state.userRejectedTransaction) { // TODO: zh_CN translation
      return this.renderUserError('Wallet user rejected transaction');
    } else if (this.state.notUsingDAppBrowser) {
      if (this.state.stage === stages.RULE) {
        return this.renderRule();
      }
      return this.renderHubDownload();
    } else if (!this.state.web3) {
      if (this.state.stage === stages.ERROR) {
        return this.renderError();
      } else {
        return (
          <Layout lang={this.state.browserLanguage}>
            <div>Loading Web3, accounts, and contract...</div>
          </Layout>
        );
      }
    }

    if (this.state.stage === stages.ERROR) {
      return this.renderError();
    } else if (this.state.stage === stages.RULE) {
      return this.renderRule();
    } else if (this.state.stage === stages.HOME) {
      return this.renderHome();
    } else if (this.state.stage === stages.DEPOSIT) {
      return this.renderDeposit();
    } else if (this.state.stage === stages.DEPOSIT_DETAIL) {
      return this.renderDepositDetail();
    } else if (this.state.stage === stages.REFERRAL_DETAIL) {
      return this.renderReferralDetail();
    }
  }

  renderError() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div>Error during {this.state.errorMsg}: {this.state.error.message} <p> Please refresh the page to try again.</p></div>
      </Layout>
    );
  }

  renderRule() {
    let qas = [];
    for (let i = 0; i < 7; i++) {
      if (i === 0) {
        qas.push(
          <div className="qa-pair" key={'ruleQA' + i}>
            <div>{this.getWord(`ruleQA${i}q`)}</div>
            <div>
              {this.getWord(`ruleQA${i}a_before`)}
              <a href={this.getWord(`ruleQA${i}a_url`)}>{this.getWord(`ruleQA${i}a_url`)}</a>
              {this.getWord(`ruleQA${i}a_after`)}
            </div>
          </div>
        );
      } else {
        qas.push(
          <div className="qa-pair" key={'ruleQA' + i}>
            <div>{this.getWord(`ruleQA${i}q`)}</div>
            <div>{this.getWord(`ruleQA${i}a`)}</div>
          </div>
        );
      }
    }
    let cautions = [];
    for (let i = 0; i < 7; i++) {
      if ([1].indexOf(i) !== -1) {
        cautions.push(
          <li key={'guideline' + i}>
            {this.getWord(`ruleCaution${i}_before`)}
            <a href={this.getWord(`ruleCaution${i}_url`)}>{this.getWord(`ruleCaution${i}_url`)}</a>
            {this.getWord(`ruleCaution${i}_after`)}
          </li>
        );
      } else if ([5].indexOf(i) !== -1) {
        cautions.push(
          <li key={'guideline' + i}>
            {this.getWord(`ruleCaution${i}_before`)}
            <a href={'mailto:' + this.getWord('ruleContactInfo_email')}>{this.getWord('ruleContactInfo_email')}</a>
            {this.getWord(`ruleCaution${i}_after`)}
          </li>
        );
      } else {
        cautions.push(
          <li key={'guideline' + i}>{this.getWord(`ruleCaution${i}`)}</li>
        );
      }
    }

    return (
      <Layout lang={this.state.browserLanguage}>
        <div className='general-view rule-page'>
          {this.renderHeader()}
          <h2>{this.getWord('ruleIntroTitle')}</h2>
          <p>{this.getWord('ruleIntro')}</p>
          <h2>{this.getWord('ruleGoalTitle')}</h2>
          <p>{this.getWord('ruleGoal')}</p>
          <h2>{this.getWord('ruleRewardTitle')}</h2>
          <p>{this.getWord('ruleReward')}</p>
          <p>{this.getWord('ruleReward1a')}</p>
          <p>{this.getWord('ruleReward1b')}</p>
          <div className='aprTable'>
            <p>{this.getWord('ruleReward1cTitle')}</p>
            <ul>
              <li>{this.getWord('ruleReward1c_1')}</li>
              <li>{this.getWord('ruleReward1c_2')}</li>
              <li>{this.getWord('ruleReward1c_3')}</li>
            </ul>
          </div>
          <div className='aprTableDesc'>{this.getWord('ruleReward1cDesc')}</div>
          <p>{this.getWord('ruleReward1_1')}</p>
          <p>{this.getWord('ruleReward1_2a')}</p>
          <p>{this.getWord('ruleReward1_2b')}</p>
          <p>{this.getWord('ruleReward1_2c')}</p>
          <p>{this.getWord('ruleReward1_3')}</p>
          <p>{this.getWord('ruleReward2a')}</p>
          <div className='aprTable'>
            <p>{this.getWord('ruleReward2bTitle')}</p>
            <ul>
              <li>{this.getWord('ruleReward2b_1')}</li>
              <li>{this.getWord('ruleReward2b_2')}</li>
              <li>{this.getWord('ruleReward2b_3')}</li>
            </ul>
          </div>
          <div className='aprTableDesc'>{this.getWord('ruleReward2bDesc')}</div>
          <p>{this.getWord('ruleReward2_1')}</p>
          <p>{this.getWord('ruleReward2_2')}</p>
          <ul>
            <li>{this.getWord('ruleReward2_2a')}</li>
            <li>{this.getWord('ruleReward2_2b')}</li>
            <li>{this.getWord('ruleReward2_2c')}</li>
            <li>{this.getWord('ruleReward2_2d')}</li>
          </ul>
          <h2>{this.getWord('ruleQATitle')}</h2>
          {qas}
          <h2>{this.getWord('ruleCautionTitle')}</h2>
          <p>{cautions}</p>
          <h2>{this.getWord('ruleContactTitle')}</h2>
          <p>
            {this.getWord('ruleContactInfo_before')}
            <a href={'mailto:' + this.getWord('ruleContactInfo_email')}>{this.getWord('ruleContactInfo_email')}</a>
            {this.getWord('ruleContactInfo_after')}
          </p>
          <div className="buttons">
            <Button
              className="backBtn"
              variant="hodl-primary"
              onClick={() => this.setState({ stage: stages.HOME })}
            >
              {this.getWord("back")}
            </Button>
          </div>
        </div>
      </Layout>
    )
  }


  renderDeposit() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div className='general-view deposit-page'>
          {this.renderHeader()}
          {this.renderDepositHeader()}
          {this.state.depositStep === 1 ? this.renderDepositStep1() : this.renderDepositStep2()}
        </div>
      </Layout>
    );
  }

  renderDepositHeader() {
    return (
      <div className='deposit-steps'>
        <div className={`steps header1 ${this.state.depositStep === 1 ? 'active' : 'inactive'}`}>
          <div className='number'>1</div>
          <div className='description'>{this.getWord('deposit')}</div>
        </div>
        <div className={`steps header2 ${this.state.depositStep === 2 ? 'active' : 'inactive'}`}>
          <div className='number'>2</div>
          <div className='description'>{this.getWord('confirm_')}</div>
        </div>
      </div>
    );
  }

  handleDepositPackageChange(event) {
    this.setState({inputPackage: isNaN(parseInt(event.target.value)) ? '' : parseInt(event.target.value)});
  }

  handleReferrerChange(event) {
    this.setState({referrer: event.target.value});
  }

  handleCheckTos() {
    this.setState({agreeTos: !this.state.agreeTos});
  }

  handleCheckLock() {
    this.setState({agreeLock: !this.state.agreeLock});
  }

  depositPageSelectedOption() {
    let idx = this.state.inputPackage;
    if (idx === null) {
      return null
    }
    return this.state.depositOptions[idx];
  }

  depositPageInputPrincipal()  {
    let depositOptionIdx = this.state.inputPackage;
    if (depositOptionIdx === '') {
      return new BN(0);
    }
    return this.state.depositOptions[depositOptionIdx].principal;
  }

  depositPagePrincipalPlusInterestAtMaturity() {
    return calcPrincipalPlusInterestAtMaturity(this.depositPageSelectedOption());
  }

  renderDepositOptions() {
    if (this.state.depositOptions.length === 0) {
      return (
        <div className='amount-input'>
          <div className='no-packages'>{this.getWord('noPackages')}</div>
        </div>
      );
    }
    return (
      <div className='amount-input'>
        {this.state.depositOptions.map((o, idx) => {
          if (o.remainingSeats.isZero()) return;
          return (
          <div key={`deposit-option-${idx}`}>
            <input id={`radio${idx}`} type="radio" name="package" value={idx} onChange={(event) => this.handleDepositPackageChange(event)} checked= {idx === this.state.inputPackage}/>
            <label htmlFor={`radio${idx}`}>
              <div className='deposit-option-flex'><span>{roundedTT(o.principal)} TT</span></div>
              <div className='deposit-option-flex'><span>&nbsp;/&nbsp;</span></div>
              <div className='deposit-option-flex'><span>{o.lockupPeriod.toString()} {this.getWord('l_days')} {Transform.interestRateStr(o.aprNumer, o.aprDenom)} {this.getWord('apr')}</span></div>
            </label>
          </div>
          );
        })}
      </div>
    );
  }

  renderReferrer() {
    if (this.state.hasDeposited) {
      return;
    }
    return (
      <div className='referrer'>
        <div className='referrer-title'>{this.getWord('referrerAddress')}</div>
        <div className='referrer-input'>
          <input type="text" value={this.state.referrer} placeholder={this.getWord('inputReferrerAddress')} onChange={(event) => this.handleReferrerChange(event)} />
        </div>
        {this.renderReferrerError()}
      </div>
    );
  }

  renderReferrerError() {
    if (!validAddress(this.state.web3, this.state.referrer)) {
      return (
        <div className='referrer-error'>{this.getWord('invalidAddress')}</div>
      );
    }
    if (isSameAddress(this.state.accounts[0], this.state.referrer)) {
      return (
        <div className='referrer-error'>{this.getWord('cantReferSelf')}</div>
      );
    }
    return;
  }

  renderAmountAvailable() {
    return (
      <div className='amount-available'>{this.getWord('available')} {TT(this.state.accountBalance)} TT</div>
    );
  }

  renderDepositStep1() {
    return (
      <div className='steps1'>
        <div className='amount'>
          <div className='amount-head'>
            <div className='amount-title'>{this.getWord('selectPackage')}</div>
            {this.renderAmountAvailable()}
          </div>
          {this.renderDepositOptions()}
        </div>
        <div className='expected-payout'>
          <img className='icon' src={ic_coin} alt='icon'/>
          <div className='expected-title'>{this.getWord('expectedTotalPayout')}</div>
          <div className='expected-value'>{TT(this.depositPagePrincipalPlusInterestAtMaturity())} TT</div>
        </div>
        {this.renderReferrer()}
        <div className='agree-policy'>
          <div className='policy-title'>{this.getWord('agreePolicy')}</div>
          <div>
            <input id='check-tos' className='form-check-input' type='checkbox' checked={this.state.agreeTos} onChange={() => this.handleCheckTos()} />
            <label className='policy' htmlFor='check-tos'>
      {this.getWord('iAgreePolicy')}<a href='/tos.html'>{this.getWord('TOS')}</a>{this.getWord('and')}<a href='/privacy_policy.html'>{this.getWord('privacyPolicy')}</a>.
            </label>
          </div>
          <div>
            <input id='check-lock' className='form-check-input' type='checkbox' checked={this.state.agreeLock} onChange={() => this.handleCheckLock()} />
            <label className='policy' htmlFor='check-lock'>
              {this.getWord('iAgreeLock')}
            </label>
          </div>
        </div>
        <div className='attention'>
          <div className='attention-title'>{this.getWord('matters')}</div>
          <div className='attention-content'>
            <div className='attention-icon'></div>
            <div className='attention-item'>{this.getWord('releaseTillEnd')}</div>
          </div>
          <div className='attention-content'>
            <div className='attention-icon'></div>
            <div className='attention-item'>{this.getWord('autoRenewed')}</div>
          </div>
          <div className='attention-content'>
            <div className='attention-icon'></div>
            <div className='attention-item'>{this.getWord('applicableReferralRewards')}</div>
          </div>
        </div>
        {this.renderButton()}
      </div>
    );
  }

  renderButton() {
    if (this.checkDepositInfo()) {
      return (
        <div className='buttons'>
          <Button variant='Secondary' className='btn-cancel' onClick={() => this.setState({stage: stages.HOME})}>{this.getWord('cancel')}</Button>
          <Button variant='Secondary' className='btn-deposit active' onClick={() => this.setState({depositStep: 2})}>{this.getWord('nextStep')}</Button>
        </div>
      );
    }
    return (
      <div className='buttons'>
        <Button variant='Secondary' className='btn-cancel' onClick={() => this.setState({stage: stages.HOME})}>{this.getWord('cancel')}</Button>
        <Button variant='Secondary' className='btn-deposit disabled' disabled onClick={() => this.setState({depositStep: 2})}>{this.getWord('nextStep')}</Button>
      </div>
    );
  }

  checkDepositInfo() {
    return this.state.agreeTos && this.state.agreeLock && this.state.inputPackage !== null && validAddress(this.state.web3, this.state.referrer);
  }

  renderDepositStep2() {
    const {inputPackage, referrer, blockTime} = this.state;
    const depositOption = this.depositPageSelectedOption();
    const principal = depositOption.principal;
    const lockupPeriod = depositOption.lockupPeriod.toString();
    const now = blockTime;
    return (
      <div className='steps2'>
        <div className='title'>{this.getWord('confirm_')}</div>
        <div className='content'>
          <div className='item'><div className='name'>{this.getWord('depositAmount')}</div><div className='value'>{TT(principal)} TT</div></div>
          <div className='item'><div className='name'>{this.getWord('lockedPeriod')}</div><div className='value'>{lockupPeriod} {this.getWord('l_days')} ({depositDurationStr(now, depositOption)})</div></div>
          <div className='item'><div className='name'>{this.getWord('rewardRate')}</div><div className='value'>{Transform.interestRateStr(depositOption.aprNumer, depositOption.aprDenom)}</div></div>
          <div className='item'><div className='name'>{this.getWord('expectedTotalPayout')}</div><div className='value'>{TT(this.depositPagePrincipalPlusInterestAtMaturity())} TT</div></div>
          <div className='item'><div className='name'>{this.getWord('autoRenewDate')}</div><div className='value'>{depositAutoRenewDateStr(now, depositOption)}</div></div>
          <div className='item'><div className='name'>{this.getWord('referrerAddress')}</div><div className='address'>{referrer}</div></div>
          <div className='buttons'>
            <Button variant='Secondary' className='btn-cancel' onClick={() => this.setState({depositStep: 1})}>{this.getWord('cancel')}</Button>
            <Button variant='Secondary' className='btn-deposit' onClick={() => this.callDeposit(inputPackage, principal, referrer)}>{this.getWord('deposit')}</Button>
          </div>
        </div>
      </div>
    );
  }

  async callCollect() {
    const {contract, accounts} = this.state;
    try {
      // FIXME: gas fee
      if (this.state.mask === true) {
        return;
      }

      this.state.mask = true;
      const receipt = await contract.methods.collect().send({gas: (new BN(16)).imul(HodlHelper.GAS_FEE), from: accounts[0]});
      this.state.mask = false;
      this.state.collectAmount = new BN(receipt.events.Collected.returnValues.amount);
    } catch (err) {
      if (this.w3helper.errIsUserRejectingTransaction(err)) {
        this.setState({userRejectedTransaction: true});
        return;
      }
      console.log('Exception during collect:', err);
      this.state.mask = false;
      this.setState({stage: stages.ERROR, errorMsg: 'collect', error: err});
      captureException(this.networkId, err);
      return;
    }
    this.updateRecordsAndOptions(stages.HOME);
  }

  async callDeposit(optionIdx, principal, referrer) {
    const {contract, accounts} = this.state;
    try {
      if (this.state.mask === true) {
        return;
      }

      this.state.mask = true;
      await deposit(contract, optionIdx, referrer, {value: principal, from: accounts[0]});
      this.state.mask = false;
      this.state.referrer = '';
    } catch (err) {
      if (this.w3helper.errIsUserRejectingTransaction(err)) {
        this.setState({userRejectedTransaction: true});
        return;
      }
      console.log('Exception when calling deposit:', err);
      this.state.mask = false;
      this.setState({stage: stages.ERROR, errorMsg: 'deposit', error: err});
      captureException(this.networkId, err);
      return;
    }
    this.updateRecordsAndOptions(stages.HOME);
  }

  renderDepositDetail() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div className='general-view detail-view'>
          {this.renderHeader()}
          {this.renderDepositDetailContent()}
        </div>
      </Layout>
    );
  }

  renderReferralDetail() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div className='general-view detail-view'>
          {this.renderHeader()}
          {this.renderReferralDetailContent()}
        </div>
      </Layout>
    );
  }

  renderHeader() {
    if (this.state.stage === stages.DEPOSIT && this.state.depositStep === 2) {
      return (
        <div className='general-header'>
          <div className='back' onClick={() => this.setState({depositStep: 1})}>{'< ' + this.getWord('back')}</div>
          <div className='language' onClick={() => this.handleLanguageClick()} >{this.getWord('language')}</div>
        </div>
      );
    }
    return (
      <div className='general-header'>
        <div className='back' onClick={() => this.setState({stage: stages.HOME})}>{'< ' + this.getWord('back')}</div>
        <div className='language' onClick={() => this.handleLanguageClick()} >{this.getWord('language')}</div>
      </div>
    );
  }

  getCollectibleInWording(HhMmSsStr) {
    const collectibleIn = this.getWord('collectibleIn');
    if (this.state.language === 'zh_CN') {
      return `${HhMmSsStr} ${collectibleIn}`;
    } else {
      return `${collectibleIn} ${HhMmSsStr}`;
    }
  }

  renderDepositDetailContent() {
    const blockTime = this.state.blockTime;
    const dr = this.state.deposits[this.state.depositIdx];
    const lockupPeriod = dr.lockupPeriod.toString();
    if (dr === undefined) {
      console.log('state.deposits:', this.state.deposits);
      console.log('state.depositIdx:', this.state.depositIdx);
      captureMessage(this.networkId, `depositIdx points to null record: ${this.state.depositIdx}`);
      return <div>renderViewContent: deposit record cannot be null</div>;
    }
    return (
      <div className='view-content'>
        <div className='view-title'>
          <div className='date'>{depositDurationStr(dr.startTime, dr)} </div><div className='status'>&nbsp;/ {this.getRecordStatus(depositRecordStateStr(dr, blockTime))}</div>
        </div>
        <div className='items'>
          <div className='item'>
            <div className='name'>{this.getWord('lockAmount')}</div>
            <div className='value'>{TT(depositRecordLockedPrincipal(dr, blockTime))} TT</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('lockedPeriod')}</div>
            <div className='value'>{lockupPeriod} {this.getWord('l_days')} ({depositDurationStr(dr.startTime, dr)})</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('rewardRate')}</div>
            <div className='value'>{Transform.interestRateStr(dr.aprNumer, dr.aprDenom)}</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('expectedTotalPayout')}</div>
            <div className='value'>{TT(depositRecordPrincipalPlusInterestAtMaturity(dr))} TT</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('autoRenewDate')}</div>
            <div className='value'>{depositAutoRenewDateStr(dr.startTime, dr)}</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('referrerAddress')}</div>
            <div className='address'>{filterNullAddress(dr.referrer)}</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('collectedPayout')}</div>
            <div className='value'>{TT(dr.collected)} TT</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('remainingPayout')}</div>
            <div className='value'>{TT(depositRecordPrincipalPlusInterestAtMaturity(dr).sub(dr.collected))} TT</div>
          </div>
        </div>
      </div>
    );
  }

  renderReferralDetailContent() {
    const blockTime = this.state.blockTime;
    const referral = this.state.referrals[this.state.referralIdx];
    if (referral === undefined) {
      console.log('state.referrals:', this.state.referrals);
      console.log('state.referralIdx:', this.state.referralIdx);
      captureMessage(this.networkId, `depositIdx points to null record: ${this.state.depositIdx}`);
      return <div>renderViewContent: deposit record cannot be null</div>;
    }
    return (
      <div className='view-content'>
        <div className='view-title'>
          <div className='date'>{unixTimeToDateStr(referral.startTime)} </div><div className='status'>&nbsp;/ {this.getRecordStatus(depositRecordStateStr(referral, blockTime))}</div>
        </div>
        <div className='items'>
          <div className='item'>
            <div className='name'>{this.getWord('refereeAddress')}</div>
            <div className='address'>{referral.referent}</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('referralTime')}</div>
            <div className='value'>{unixTimeToDateStr(referral.startTime)}</div>
          </div>
          <div className='item'>
            <div className='name'>{this.getWord('expectedRewards')}</div>
            <div className='value'>{TT(referralReward(referral))} TT</div>
          </div>
        </div>
      </div>
    );
  }

  renderHome() {
    return (
      <Layout lang={this.state.browserLanguage}>
        <div id='home'>
          {this.renderCollectSuccess()}
          {this.renderHomeHeader()}
          {this.renderHomeContent()}
          {this.renderHomeFooter()}
          <Modal
            show={this.state.showMaxDepositsDialog}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.handleMaxDepositsDialog}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Oops
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {this.getWord('depositPerAddressLimit')}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button className='dialog-btn' onClick={this.handleMaxDepositsDialog}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    );
  }

  renderCollectSuccess() {
    if (this.state.collectAmount === null) {
      return (<div></div>);
    }
    return (
      <div id='collectSuccess'>
        <div id='collectBanner'>
          <img className='banner' src={ig_card_success} alt='CardSuccess' />
          <div className='msg'>{this.getWord('collectSuccess')}</div>
          <div className='amount'>{TT(this.state.collectAmount)} TT</div>
          <img className='close' onClick={() => this.setState({collectAmount: null})} src={ic_close} alt='Close' />
        </div>
      </div>
    );
  }

  renderHomeHeader() {
    return (
      <div className='home-header'>
      <div className='join' onClick={() => this.setState({stage: stages.RULE})}>{this.getWord('howToJoin')}</div>
      <img className='logo' src ={ic_logo} alt='Logo'/>
      <div className='center'>
        {this.getWord('stakingService')}
        <a href='https://staking.thundercore.com' className='visit'>
          <span>{this.getWord('visitV1')}</span>
          <img className='forward' src ={ic_forward} alt='Logo'/>
        </a>
      </div>
      <div className='language' onClick={() => this.handleLanguageClick()} >{this.getWord('language')}</div>
      </div>
    );
  }

  handleLanguageClick() {
    if (this.state.language === 'en') {
      this.setState({language: 'zh_CN'});
    } else {
      this.setState({language: 'en'});
    }
  }

  handleTabSelect(selectedTabKey) {
    this.setState({
      activeKey: selectedTabKey
    });
  }

  handleMaxDepositsDialog = () => {
    this.setState({
      hasViewMaxDepositsDialog: true,
      showMaxDepositsDialog: !this.state.showMaxDepositsDialog
    })
  }

  getWord(key) {
    return dict[key][this.state.language];
  }

  renderHomeDepositDisabledInfo() {
    return (
      <div className="deposit-disable-info-container">
        <div id="depositDisableInfo" className="deposit-disable-info">
          <img className="info" src={ic_info} alt="Info" />
          <img
            className="cross"
            src={ic_cross}
            alt="X"
            onClick={() => {
              this.hideDepositDisableInfo()
            }}
          />
          <div className="info-header">
            {this.getWord("depositeDisabledInfoHeader")}
          </div>
          <div className="info-body">
            {this.getWord("depositeDisabledInfoBody")}
          </div>
        </div>
      </div>
    )
  }

  hideDepositDisableInfo() {
    var element = document.getElementById("depositDisableInfo")
    element.classList.add("hide")
  }

  renderHomeContent() {
    return (
        <div className='home-content'>
          {this.renderHomeBalanceBanner()}
          {this.renderHomeDepositDisabledInfo()}
          <Tabs activeKey={this.state.activeKey} onSelect={this.handleTabSelect}>
            <Tab eventKey='action' title={this.getWord('stakingSummary')}>
              {this.renderSummaryDeposit()}
              {this.renderSummaryCollect()}
              {this.renderAutoRenewPayoutNote()}
              {this.renderPayoutDetail()}
            </Tab>
            <Tab eventKey='list' title={this.getWord('depositRecords')}>
              {this.renderHomeDeposits()}
            </Tab>
            <Tab eventKey='referral' title={this.getWord('referralRecords')}>
              {this.renderHomeReferrals()}
            </Tab>
          </Tabs>
        </div>
    );
  }

  renderHomeFooter() {
    return (
      <div className='home-footer'>
        <div className='tos'>
          {this.getWord('viewThe')}<a href={this.getWord('TOSLink')}>{this.getWord('TOS')}</a>{this.getWord('and')}<a href={this.getWord('privacyPolicyLink')}>{this.getWord('privacyPolicy')}</a>.
        </div>
        <div className='copyright'>
          &copy;{this.getWord('copyright')}
        </div>
      </div>
    );
  }

  renderHomeBalanceBanner() {
    return (
      <div className='balance-banner-wrapper'>
        <div className='balance-banner'>
          <div className='balance-header'>{this.getWord('balance')}</div>
          <div className='balance'>{TT(principalPlusInterestAtMaturityMinusCollected(this.state.deposits, this.state.referrals))} TT</div>
        </div>
      </div>
    );
  }

  renderSummaryDepositButton() {
    return (
      isOpening ? (
        <Button variant='Secondary' active onClick={() => { this.updateAccountBalance(); }}>
          {this.getWord('deposit')}
        </Button>
      ) : (<Button variant='Secondary disabled' disabled>
            {this.getWord('deposit')}
          </Button>
      )
    )
  }

  renderSummaryDeposit() {
    return (
      <div className='deposit'>
        <img className='icon' src={ic_coin} alt='Icon'/>
        <div className='header'>
          {this.getWord('locked')}
        </div>
        <div className='content'>
          {TT(lockedPrincipalSum(this.state.deposits, this.state.blockTime))} TT
        </div>
        {this.renderSummaryDepositButton()}
      </div>
    );
  }

  renderAutoRenewPayoutNote() {
    const {blockTime, deposits} = this.state;
    if (hasAutoDepositRecord(blockTime, deposits)) {
      return (
        <div className='auto-renew-payout-note'>
          <img className='icon' src={ic_important} alt='Icon'/>
          <div className='text'>{this.getWord('autoRenewPayoutNote')}</div>
        </div>
      );
    }
  }

  renderPayoutDetail() {
    const {collectible, blockTime, deposits, referrals} = this.state;
    if (!collectible.isZero()) {
      return (
        <div className='payout-details'>
          {this.getWord('payoutDetails')}
          <hr/>
          <ul>
            <li>
              <span>{this.getWord('stakingReward')}</span>
              <span className='value'>{TT(depositCollectableInterests(deposits))} TT</span>
            </li>
            <li>
              <span>{this.getWord('referralRewards')}</span>
              <span className='value'>{TT(referralCollectables(referrals))} TT</span>
            </li>
            <li>
              <span>{this.getWord('stakedFunds')}</span>
              <span className='value'>{TT(depositCollectablePrincipals(blockTime, deposits))} TT</span>
            </li>
          </ul>
        </div>
      );
    }
    return;
  }

  renderSummaryCollect() {
    return (
      <div className='collect'>
        <img className='icon' src={ic_coin} alt='Icon'/>
        <div className='header'>
      {this.getWord('totalPayout')}
        </div>
        <div className='content'>
          {TT(this.state.collectible)} TT
        </div>
        {this.renderCollectButton()}
      </div>
    );
  }

  renderCollectButton() {
    if (!this.state.collectible.isZero()) {
        return (<Button variant='Secondary' active onClick={() => { this.callCollect(); }}>{this.getWord('collect')}</Button>);
    } else {
        return (<Button variant='Secondary disabled' disabled>{this.getWord('collect')}</Button>);
    }
  }

  getRecordStatus(str) {
    if (str === 'ongoing') {
      return this.getWord('locked');
    } else if (str === 'matured') {
      return this.getWord('matured');
    } else {
      return str;
    }
  }

  renderHomeReferrals() {
    return (
      <div>
        {this.state.referrals.map((r, index) => { return (
          <div className='deposit-record' key={`deposit-record-${index}`}>
              <div className='cover'>
              </div>
              <div className='date'>
                {unixTimeToDateStr(r.startTime)} / {this.getRecordStatus(depositRecordStateStr(r, this.state.blockTime))}
              </div>
              <div className='amount'>
                {shortAddress(r.referent)}
              </div>
              <div className='auto-renew'>
                {this.getWord('referralRewards')}: {TT(referralReward(r))}TT
              </div>
          <Button variant='Secondary' onClick={() => this.setState({stage: stages.REFERRAL_DETAIL, referralIdx: index})}>
          {this.getWord('view')}
              </Button>
        </div>)})}
      </div>
    );
  }

  renderHomeDeposits() {
    return (
      <div>
        {this.state.deposits.map((r, index) => { return (
          <div className='deposit-record' key={`deposit-record-${index}`}>
              <div className='cover'>
              </div>
              <div className='date'>
                {depositDurationStr(r.startTime, r)} / {this.getRecordStatus(depositRecordStateStr(r, this.state.blockTime))}
              </div>
              <div className='amount'>
                {TT(r.principal)} TT
              </div>
              <div className='auto-renew'>
                {this.getWord('autoRenew')}: {depositAutoRenewDateStr(r.startTime, r)}
              </div>
          <Button variant='Secondary' onClick={() => this.setState({stage: stages.DEPOSIT_DETAIL, depositIdx: index})}>
          {this.getWord('view')}
              </Button>
        </div>)})}
      </div>
    );
  }

  // renderDebug is a poor man's debug console
  // mainly for the TrustWallet mobile app
  renderDebug() {
    return (
      <div>
        {window.myDebugMessages.map((m, index) => { return (
          <div className='debug-message' key={`debug-message-${index}`}>
              <div>
                {`${index}: ${m}`}
              </div>
        </div>)})}
      </div>
    );
  }
}

Hodl.propTypes = {
  location: PropTypes.object,
}

export default Hodl
