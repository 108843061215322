import Web3 from "web3";

/* arrayEquals only works on arrays of types that can be compared with '===' */
function arrayEquals(a, b) {
  //console.log('arrayEquals', a, b);
  if (a.length != b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

// User visiting DApp using normal Browser
const errNotUsingDAppBrowser = new Error('using non-DApp browser');
const errRefusedToUnlockWallet = new Error('user refused to unlock wallet');

class Web3Helper {
  constructor() {
    this._accounts = null;
    this._networkId = null;
    const self = this;
    if (window.ethereum) {
      // TrustWallet could inject a `window.ethereum` obj without an `on` method
      if (window.ethereum.on) {
        window.ethereum.on('accountsChanged', function (accounts) {
          console.log('ethereum.accountsChanged event, accounts:', accounts);
          if (self._accounts !== null && !arrayEquals(accounts, self._accounts)) {
            window.location.reload();
          }
        });
      }
    }
  }
  async getAccounts() {
    if (this._accounts === null) {
      this._accounts = await this._web3.eth.getAccounts();
    }
    return this._accounts;
  }
  async getNetworkId() {
    if (this._networkId === null) {
      this._networkId = await this._web3.eth.net.getId();
    }
    return this._networkId;
  }
  async getWeb3() {
    let provider;
    if (window.ethereum) {
      this._accounts = await window.ethereum.enable();
      if (this._accounts === undefined) {
        throw errRefusedToUnlockWallet;
      }
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      throw errNotUsingDAppBrowser;
    }
    this._web3 = new Web3(provider);
    return this._web3;
  }
  errIsNotUsingDAppBrowser(err) {
    return Object.is(err, errNotUsingDAppBrowser);
  }
  errIsUserRejectingTransaction(err) {
    if (err.message === 'Returned error: Error: MetaMask Tx Signature: User denied transaction signature.') { // MetaMask
      return true;
    } else if (err.message === 'cancelled') { // TrustWallet
      return true;
    } else if (Object.is(err, errRefusedToUnlockWallet)) {
      return true;
    }
    return false;
  }
}

export default Web3Helper;
