const web3 = require('web3');
const BN = web3.utils.BN;
const {unixTimeToDateTimeStr} = require('./transform');

const NULL_ADDR = '0x0000000000000000000000000000000000000000';

class DepositRecord {
  constructor(id, startTime, principal, aprNumer, aprDenom, lockupPeriod, withdrawInterval, collected, autoDepositDuration, optionIndex, collectables) {
    this.id = new BN(id);
    this.startTime = new BN(startTime);
    this.principal = new BN(principal);
    this.aprNumer = new BN(aprNumer);
    this.aprDenom = new BN(aprDenom);
    this.lockupPeriod = new BN(lockupPeriod);
    this.withdrawInterval = new BN(withdrawInterval);
    this.collected = new BN(collected);
    this.autoDepositDuration = new BN(autoDepositDuration);
    this.optionIndex = new BN(optionIndex);
    this.collectables = new BN(collectables);
  }

  toString() {
    let str = 'DepositRecord:\n';
    str += '\tid: ' + this.id.toString() + '\n';
    str += '\tstartTime: ' + this.startTime.toString() + ' (' + unixTimeToDateTimeStr(this.startTime) + ')\n';
    str += '\tprincipal: ' + this.principal.toString() + '\n';
    str += '\tapr: ' + this.aprNumer.toString() + '/' + this.aprDenom.toString() + '\n';
    str += '\tlockupPeriod: ' + this.lockupPeriod.toString() + '\n';
    str += '\twithdrawInterval: ' + this.withdrawInterval.toString() + '\n';
    str += '\tcollected: ' + this.collected.toString() + '\n';
    str += '\tcollectables: ' + this.collectables.toString() + '\n';
    str += '\tautoDepositDuration: ' + this.autoDepositDuration.toString() + '\n';
    str += '\toptionIndex: ' + this.optionIndex.toString() + '\n';
    str += '\treferrer: ' + this.referrer + '\n';
    return str;
  }
}

class ReferralRecord {
  constructor(id, startTime, principal, aprNumer, aprDenom, lockupPeriod, withdrawInterval, collected, collectables) {
    this.id = new BN(id);
    this.startTime = new BN(startTime);
    this.principal = new BN(principal);
    this.aprNumer = new BN(aprNumer);
    this.aprDenom = new BN(aprDenom);
    this.lockupPeriod = new BN(lockupPeriod);
    this.withdrawInterval = new BN(withdrawInterval);
    this.collected = new BN(collected);
    this.collectables = new BN(collectables);
  }

  toString() {
    let str = 'ReferralRecord:\n';
    str += '\tid: ' + this.id.toString() + '\n';
    str += '\tstartTime: ' + this.startTime.toString() + ' (' + unixTimeToDateTimeStr(this.startTime) + ')\n';
    str += '\tprincipal: ' + this.principal.toString() + '\n';
    str += '\tapr: ' + this.aprNumer.toString() + '/' + this.aprDenom.toString() + '\n';
    str += '\tlockupPeriod: ' + this.lockupPeriod.toString() + '\n';
    str += '\twithdrawInterval: ' + this.withdrawInterval.toString() + '\n';
    str += '\tcollected: ' + this.collected.toString() + '\n';
    str += '\tcollectables: ' + this.collectables.toString() + '\n';
    str += '\treferent: ' + this.referent + '\n';
    return str;
  }
}

function toDepositRecord(list) {
  return new DepositRecord(list[0], list[1], list[2], list[3], list[4], list[5], list[6], list[7], list[8], list[9], list[10]);
}

function toReferralRecord(list) {
  return new ReferralRecord(list[0], list[1], list[2], list[3], list[4], list[5], list[6], list[7], list[8]);
}

async function getDeposits(contract, account, web3Option={}) {
  return contract.methods
    .getDeposits(account)
    .call(web3Option)
    .then(({0: blockTime, 1: deposits, 2: referrers}) => {
      let records = [];
      for (let i = 0; i < deposits.length; i++) {
        let r = toDepositRecord(deposits[i]);
        r.referrer = referrers[i];
        records.push(r);
      }
      return [new BN(blockTime), records];
    });
}

async function getReferrals(contract, account, web3Option={}) {
  return contract.methods
    .getReferrals(account)
    .call(web3Option)
    .then(({0: blockTime, 1: referrals, 2: referents}) => {
      let records = [];
      for (let i = 0; i < referrals.length; i++) {
        let r = toReferralRecord(referrals[i]);
        r.referent = referents[i];
        records.push(r);
      }
      return [new BN(blockTime), records];
    });
}

async function depositRecords(contract, account, idx, web3Option) {
  return getDeposits(contract, account, web3Option).then((r) => {
    const [, records] = r;
    return records[idx.toNumber()];
  });
}

async function referralRecords(contract, account, idx, web3Option) {
  return getReferrals(contract, account, web3Option).then((r) => {
    const [, records] = r;
    return records[idx.toNumber()];
  });
}

async function deposit(contract, optionIdx, referrer, web3Option, estimateGas) {
  if (referrer.toString() === '') {
    referrer = NULL_ADDR;
  }
  const method = contract.methods
    .deposit(optionIdx.toString(), referrer.toString());
  if (estimateGas) {
    web3Option.gas = await method.estimateGas(web3Option);
  }
  return method.send(web3Option);
}

async function hasDeposited(contract, account, web3Option) {
  return contract.methods.hasDeposited(account).call(web3Option);
}

module.exports = {
  depositRecords,
  referralRecords,
  getDeposits,
  getReferrals,
  deposit,
  hasDeposited,
  NULL_ADDR,
};
