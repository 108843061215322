export const dict = {
  language: {
    en:    '中文',
    zh_CN: 'English',
  },
  stakingService: {
    en:    'SuperNode Staking',
    zh_CN: '超级节点 (权益池) 激励计划',
  },
  balance: {
    en:    'SuperNode Balance',
    zh_CN: '超级节点总额',
  },
  stakingSummary: {
    en:    'Staking Summary',
    zh_CN: '权益概况',
  },
  depositRecords: {
    en:    'Staking Records',
    zh_CN: '权益纪录',
  },
  referralRecords: {
    en:    'Referral Records',
    zh_CN: '推荐纪录',
  },
  locked: {
    en:    'Locked',
    zh_CN: '锁仓',
  },
  deposit: {
    en:    'Deposit',
    zh_CN: '充币',
  },
  totalPayout: {
    en:    'Total Payout',
    zh_CN: '可提币额',
  },
  collect: {
    en:    'Collect',
    zh_CN: '提币',
  },
  viewThe: {
    en:    'View the ',
    zh_CN: '查看',
  },
  TOS: {
    en:    'Terms Of Service',
    zh_CN: '使用协议',
  },
  TOSLink: {
    en:    'https://www.thundercore.com/terms.html',
    zh_CN: 'https://www.thundercore.com/terms.html',
  },
  and: {
    en:    ' and ',
    zh_CN: '和',
  },
  privacyPolicy: {
    en:    'Privacy Policy',
    zh_CN: '保密协议',
  },
  privacyPolicyLink: {
    en:    'https://www.thundercore.com/policy.html',
    zh_CN: 'https://www.thundercore.com/policy.html',
  },
  back: {
    en:    'Back',
    zh_CN: '返回',
  },
  confirm_: {
    en:    'Confirm',
    zh_CN: '确认',
  },
  depositAmount: {
    en:    'Deposit Amount',
    zh_CN: '充币数量',
  },
  selectPackage: {
    en:    'Select Deposit Package',
    zh_CN: '选择锁仓方案',
  },
  lockedPeriod: {
    en:    'Lock-up Period',
    zh_CN: '锁仓期',
  },
  rewardRate: {
    en:    'Reward Rate (APR)',
    zh_CN: '奖励率',
  },
  expectedTotalPayout: {
    en:    'Expected Total Payout',
    zh_CN: '预期总币额',
  },
  cancel: {
    en:    'Cancel',
    zh_CN: '取消',
  },
  ongoing: {
    en:    'Ongoing',
    zh_CN: '进行中',
  },
  view: {
    en:    'View',
    zh_CN: '查看',
  },
  wrongNetwork: {
    en:    'Please switch to the ThunderCore blockchain.',
    zh_CN: '请切换到TT链',
  },
  lockAmount: {
    en:    'Locked Amount',
    zh_CN: '锁仓数量',
  },
  collectedPayout: {
    en:    'Collected Payout',
    zh_CN: '已提取币额',
  },
  remainingPayout: {
    en:    'Remaining Payout',
    zh_CN: '剩余币额',
  },
  collectibleIn: {
    en:    'Collectible in ',
    zh_CN: ' 后可提取',
  },
  available: {
    en:    'Available',
    zh_CN: '可用币',
  },
  day: {
    en:    'Days',
    zh_CN: '天',
  },
  days: {
    en:    'Days',
    zh_CN: '天',
  },
  l_days: {
    en:    'days',
    zh_CN: '天',
  },
  year: {
    en:    'Year',
    zh_CN: '年',
  },
  years: {
    en:    'Years',
    zh_CN: '年',
  },
  agreePolicy: {
    en:    'I Agree to these Policies',
    zh_CN: '使用条款',
  },
  iAgreePolicy: {
    en:    'I agree to the ',
    zh_CN: '我同意',
  },
  iAgreeLock: {
    en:    'I agree tokens will be locked till the period ends.',
    zh_CN: '我同意锁仓TT币直至锁仓期结束',
  },
  matters: {
    en:    'Attention',
    zh_CN: '注意事项',
  },
  releaseTillEnd: {
    en:    'The funds could not be collected until the end of the period.',
    zh_CN: 'TT币将无法提取直至锁仓期结束。',
  },
  autoRenewed: {
    en:    'The deposit would be auto-renewed 3 days after the end of the period.',
    zh_CN: '此笔权益本金将于锁仓期结束的三天后自动续约。',
  },
  applicableReferralRewards: {
    en:    'The referral rewards is only applicable for your referee\'s first staked funds at first year.',
    zh_CN: '推荐奖励仅适用于您被推荐人的第一笔锁仓的权益本金，第二年不再适用。',
  },
  nextStep: {
    en:    'Next Step',
    zh_CN: '下一步',
  },
  collectSuccess: {
    en:    'Collect Success',
    zh_CN: '提币成功',
  },
  matured: {
    en:    'Matured',
    zh_CN: '到期',
  },
  useDAppBrowser: {
    en:    'Please visit this page in a DApp browser that supports the ThunderCore blockchain or use ThunderCore Hub.',
    zh_CN: '请使用支援 TT 链的加密货币钱包，或 TT 站，访问参与本计划。 ',
  },
  copyright: {
    en:    'All Rights Reserved by Thunder Token Ltd.',
    zh_CN: 'All Rights Reserved by Thunder Token Ltd.',
  },
  referrerAddress: {
    en:    'Referrer Address',
    zh_CN: '推荐人地址',
  },
  inputReferrerAddress: {
    en:    'Input referrer wallet address',
    zh_CN: '输入推荐人钱包地址',
  },
  autoRenewDate: {
    en:    'Auto-Renew Date',
    zh_CN: '自动续约日期',
  },
  refereeAddress: {
    en:    'Referee Address',
    zh_CN: '被推荐人地址',
  },
  referralTime: {
    en:    'Referral Time',
    zh_CN: '推荐时间',
  },
  expectedRewards: {
    en:    'Expected Total Referral Rewards',
    zh_CN: '预期总推荐奖励',
  },
  howToJoin: {
    en:    'Rules',
    zh_CN: '规则',
  },
  visitV1: {
    en:    'Visit V1',
    zh_CN: '访问 V1',
  },
  noPackages: {
    en:    'Sorry, all seats in all deposit options have been taken',
    zh_CN: '抱歉，锁仓方案已全部额满',
  },
  notEnoughBalance: {
    en:    'Not enough balance to stake',
    zh_CN: '钱包馀额不足',
  },
  invalidAddress: {
    en:    'This is not a valid address',
    zh_CN: '输入的不是合法地址',
  },
  cantReferSelf: {
    en:    'Can\'t use your own address as the referrer',
    zh_CN: '推荐人地址不可填自己的钱包地址',
  },
  autoRenew: {
    en:    'Auto-Renew',
    zh_CN: '自动续约',
  },
  referralRewards: {
    en:    'Referral Rewards',
    zh_CN: '推荐奖励',
  },
  payoutDetails: {
    en:    'Payout Details',
    zh_CN: '可提币额明细',
  },
  stakingReward: {
    en:    'Staking Reward',
    zh_CN: '权益奖励',
  },
  apr: {
    en:    'APR',
    zh_CN: '奖励率',
  },
  stakedFunds: {
    en:    'Staked Funds',
    zh_CN: '权益本金',
  },
  ruleIntroTitle: {
    en:    'SuperNode Staking',
    zh_CN: '超级节点 (权益池) 激励计划',
  },
  ruleIntro: {
    en:    'Stake your Thunder Tokens and receive rewards for helping secure our chain',
    zh_CN: '运用您的 TT 币，参与 TT 公链安全性的维护，并从而获得奖励。',
  },
  ruleGoalTitle: {
    en:    'Purpose of Program',
    zh_CN: '计划目的',
  },
  ruleGoal: {
    en:    'SuperNode Staking is designed to reward Thunder Token holders for their contribution to the security of our state of the art Proof of Stake blockchain. Participants could deposit TT to earn staking rewards up to 24% APR. Inviting friends to join allows one to earn additional referral rewards up to 10% APR from each referee.',
    zh_CN: '超级节点 (权益池) 激励计划是一个健全且完整的奖励结构，旨在提供奖励予所有维护 TT 公链安全的代币持有者。参与者存入 TT 币，可获取权益奖励高达 24% APR。每成功推荐一位好友，即可赚其最高 10% APR 的推荐奖励，推荐无上限！',
  },
  ruleRewardTitle: {
    en:    'Rules for Rewards',
    zh_CN: '奖励规则',
  },
  ruleReward: {
    en:    'There are two ways to receive rewards from this program:',
    zh_CN: '本计划共有两种获取奖励方式：',
  },
  ruleReward1a: {
    en:    '1. Staking Rewards: deposit your Thunder Tokens into this service and you\'ll earn Staking Rewards.',
    zh_CN: '1. 权益奖励：用支援TT链的加密货币钱包，存入TT币到此服务中，可获得的奖励，即为权益奖励。',
  },
  ruleReward1b: {
    en:    'There are three deposit options each with different APRs and number of seats available:',
    zh_CN: '提供三种锁仓方案，分别享受不同的年化奖励率，各方案皆有名额限制，从计划开始至额满为止，请参考以下：',
  },
  ruleReward1cTitle: {
    en:    'Deposit amount / APR / Available seats',
    zh_CN: '锁仓量 / 年化奖励率（APR) / 名额',
  },
  ruleReward1c_1: {
    en:    '100,000,000 TT / 24% / 9 slots',
    zh_CN: '100,000,000 TT / 24% / 9 名',
  },
  ruleReward1c_2: {
    en:    '10,000,000 TT / 18% / 99 slots',
    zh_CN: '10,000,000 TT / 18% / 99 名',
  },
  ruleReward1c_3: {
    en:    '1,000,000 TT / 15% / 999 slots',
    zh_CN: '1,000,000 TT / 15% / 999 名',
  },
  ruleReward1cDesc: {
    en:    'Staking Annual Rewards Rates',
    zh_CN: '权益奖励年化奖励率',
  },
  ruleReward1_1: {
    en:    '1-1. Lock-up period: the lock-up period is 360 days, starting from the day of deposit.',
    zh_CN: '1-1. 锁仓期计算方式：360 天 ，起始日为锁仓日，到期日为锁仓日 + 360 天。',
  },
  ruleReward1_2a: {
    en:    '1-2. Staking Rewards Collection Time: you can collect the portion of your staking rewards that has matured after 90 days, 180 days, 270 days, and 360 days of depositing into this program.',
    zh_CN: '1-2. 期满返利: 权益奖励于锁仓后 90 天、 180 天、270  天、 360 天，允许提取期满的部份奖励。上述期限后的任意时间，皆可提取期满的奖励。',
  },
  ruleReward1_2b: {
    en:    'For example: ',
    zh_CN: '例如：',
  },
  ruleReward1_2c: {
    en:    'If you collect Staking Rewards 120 days after depositing, the matured portion you can collect is for the first 90 days',
    zh_CN: '若90天锁仓期满后未立刻提取奖励，而是在第120天时领取，可提取的奖励为前90天的权益奖励。',
  },
  ruleReward1_3: {
    en:    '1-3. Deposits in this service will be automatically renewed if not collected within 3 days after the end of the lock-up period. Please collect your funds during that three day time window or your funds would be locked for an additional 360 days.',
    zh_CN: '1-3. 期满未领回则自动续约：锁仓期结束后，3天内领回本金 。若超过3天未领回， 将自动以本金续约 360 天。',
  },
  ruleReward2a: {
    en:    '2. Referral Rewards: Invite a friend to deposit Thunder Tokens and fill-in your ThunderCore wallet address as the referrer address to receive referral rewards. The amount is determined by the following table and there is no limit on the number of friends you can invite.',
    zh_CN: '2. 推荐奖励：被推荐人在锁仓时，填写推荐人的TT链钱包地址，推荐人即可赚取被推荐人之锁仓量所对应的推荐奖励，推荐人数无上限。',
  },
  ruleReward2bTitle: {
    en:    'Referee\'s Deposit amount / Referral reward APR',
    zh_CN: '被推荐人锁仓量 / APR',
  },
  ruleReward2b_1: {
    en:    '100,000,000 TT  / 10%',
    zh_CN: '100,000,000 TT  / 10%',
  },
  ruleReward2b_2: {
    en:    '10,000,000 TT  / 8%',
    zh_CN: '10,000,000 TT  / 8%',
  },
  ruleReward2b_3: {
    en:    '1,000,000 TT / 5%',
    zh_CN: '1,000,000 TT / 5%',
  },
  ruleReward2bDesc: {
    en:    'The APR of the referral reward you receive is determined by the amount of tokens deposited by the people you refer.',
    zh_CN: '根据每位被推荐人锁仓量而决定所赚取的推荐奖励之年化奖励率',
  },
  ruleReward2_1: {
    en:    '2-1. Referral Reward Collection Time: You can collect Referral Reward once every 30 days after your referee deposited Thunder Tokens successfully into the Program.',
    zh_CN: '2-1. 推荐奖励领取时间：被推荐人完成锁仓后，每满 30 天可提领一次。',
  },
  ruleReward2_2: {
    en:    '2-2. Additional Rules for Referral Rewards:',
    zh_CN: '2-2. 推荐奖励注意事项：',
  },
  ruleReward2_2a: {
    en:    'You only receive referral rewards for a referee’s first deposit in this program. Referral rewards are only offered during the first year of this program.',
    zh_CN: '推荐奖励仅适用于您被推荐人的第一笔锁仓的权益本金，第二年不再适用。',
  },
  ruleReward2_2b: {
    en:    'Your wallet address must be correctly filled-in by a referee for you to receive referral rewards.',
    zh_CN: '推荐奖励只有当被推荐人正确填写推荐人钱包地址时才可领取。',
  },
  ruleReward2_2c: {
    en:    'You can receive referral rewards even if you don\'t deposit tokens into the program yourself.',
    zh_CN: '推荐人不需要参与本计划，也可领取推荐奖励。',
  },
  ruleReward2_2d: {
    en:    'You can\'t submit your own wallet address as your referrer.',
    zh_CN: '推荐人地址不可填自己的钱包地址。',
  },
  ruleQATitle: {
    en:    'FAQ',
    zh_CN: '常见问题',
  },
  ruleQA0q: {
    en:    'Q: How can I participate in SuperNode Staking?',
    zh_CN: 'Q: 如何参与超级节点 (权益池) 激励计划？',
  },
  ruleQA0a_before: {
    en:    'A: By visiting the URL (',
    zh_CN: 'A: 使用支援TT链的钱包，拜访此网址 (',
  },
  ruleQA0a_url: {
    en:    'https://supernode.thundercore.com',
    zh_CN: 'https://supernode.thundercore.com',
  },
  ruleQA0a_after: {
    en:    ') in a DApp browser that supports the ThunderCore blockchain.',
    zh_CN: ')，即可参与计划。',
  },
  ruleQA1q: {
    en:    'Q: Which tokens do we currently support?',
    zh_CN: 'Q: 当前支持哪些货币？',
  },
  ruleQA1a: {
    en:    'A: We only support Thunder Tokens.',
    zh_CN: 'A: 仅支持TT币',
  },
  ruleQA2q: {
    en:    'Q: Will SuperNode Staking be charging any fees? ',
    zh_CN: 'Q: 参与计划，需要收取哪些费用？',
  },
  ruleQA2a: {
    en:    'A: SuperNode Staking will not charge any fees for staking, the only transaction fees charged are those charged by the DApp browser when utilizing the underlying blockchain.',
    zh_CN: 'A: 此计划不收取任何费用，仅有您在充币至此计划时，您使用的钱包会收取交易手续费，手续费依据不同钱包而定。',
  },
  ruleQA3q: {
    en:    'Q: Can I collect my staked funds and rewards before the end of the lock-up period?',
    zh_CN: 'Q: 锁仓期未满，是否可以提早领回本金与奖励？',
  },
  ruleQA3a: {
    en:    'A: The staked funds must be collected during the 3 day window after the lock-up period (360 days) ends.  However, you can collect the portion of your staking rewards that has matured after 90 days, 180 days, 270 days, and 360 days of depositing into this program.',
    zh_CN: 'A: 一旦参与锁仓，360天期满后3天内才可领回本金，而权益奖励则可以于锁仓后 90天、180天、270天、360天，允许提取期满的部份奖励。',
  },
  ruleQA4q: {
    en:    'Q: What if a person I referred filled-in the wrong referrer address?',
    zh_CN: 'Q:若推荐人地址填错，该如何处理？',
  },
  ruleQA4a: {
    en:    'A: SuperNode Staking is implemented as a smart contract and the referrer address can not be changed once submitted. Please ensure the address is correct.',
    zh_CN: 'A: 本计划以智能合约运作，一旦地址填错，即不可修改，请务必确认地址正确。',
  },
  ruleQA5q: {
    en:    'Q: Can I change my wallet address of the program during the lock-up period?',
    zh_CN: 'Q: 锁仓期间，是否可以更换参与锁仓的钱包地址？',
  },
  ruleQA5a: {
    en:    'A: SuperNode Staking is implemented as a smart contract and the source address of a deposit can not be changed.',
    zh_CN: 'A: 本计划以智能合约运作，一旦参与锁仓，钱包地址无法更改，请务必确认地址。',
  },
  ruleQA6q: {
    en:    'Q: If I lost my private key, how can I collect my staked funds and rewards?',
    zh_CN: 'Q: 若遗失钱包地址的私钥，该怎么提取本金与奖励？',
  },
  ruleQA6a: {
    en:    'A: If you can not log into the Program owing to the loss of your private key, the legal responsibility lies with you and not ThunderCore. Please store your private key safely.',
    zh_CN: 'A: 若您遗失个人的钱包私钥，以至于无法登入本计划网页提取本金与奖励，ThunderCore 不负任何相关责任，请务必保管个人钱包私钥。',
  },
  ruleCautionTitle: {
    en:    'Guidelines',
    zh_CN: '注意事项',
  },
  ruleCaution0: {
    en:    'To access and participate in the Program you will have to create a ThunderCore blockchain wallet (the “Wallet”). You can accomplish this using wallets like MetaMask or TrustWallet which work with the ThunderCore blockchain. Wallets such as MetaMask are provided by third parties, and we are not responsible for any liabilities of any nature resulting from the use of such wallets. You are responsible for the management of the private keys for your Wallet. ThunderCore is not responsible for managing, storing, collecting or otherwise providing access to the private keys for your Wallet. You’re responsible for all activities that occur using your Wallet, whether or not you know about them.',
    zh_CN: '在参与本计划之前，请确保使用的是安全且支援TT链的钱包，如官方的ThunderCore Hub 或 第三方的 MetaMask 与 TrustWallet 等。若使用第三方钱包，请自主确保钱包私钥与安全性 ThunderCore 一律不负责管理、储存跟收集第三方钱包的私钥。',
  },
  ruleCaution1_before: {
    en:    'Once earned, rewards must be claimed from the  SuperNode Staking service by a participating user for the user to receive them.',
    zh_CN: '奖励将透过智能合约，自动根据您选择的锁仓方案而每日累积，您必须亲自于计划网页(',
  },
  ruleCaution1_url: {
    en:    '',
    zh_CN: 'https://supernode.thundercore.com',
  },
  ruleCaution1_after: {
    en:    '',
    zh_CN: ')中提取，奖励才会发送至您的钱包地址。',
  },
  ruleCaution2: {
    en:    'Access to the Program may require the use of your personal computer and/or mobile devices, as well as communications with or use of data and storage on such devices. You are responsible for any Internet or mobile charges that you may incur when accessing the Program.',
    zh_CN: '本计划需要您透过个人电脑或手机登入参与，过程中的产生的网路连线费用、手机费率与钱包交易手续费等，ThunderCore 一律不负责。',
  },
  ruleCaution3: {
    en:    'You acknowledge that certain information including your wallet and on­ chain transaction records are all public information and can be accessed by anyone, including participants and non­participants of the Program.',
    zh_CN: '本计划所有参与者的钱包地址与交易纪录皆为公开资讯，可于区块浏览器上浏览，如ThunderScan, ViewBlock。',
  },
  ruleCaution4: {
    en:    'Rewards earned as a result of fraudulent activities are null and void. ThunderCore reserves the right to request information about, review and investigate all Program activities, and to disqualify participants if we notice any activity that we believe is abusive, fraudulent or do not meet Program requirements. ThunderCore may determine ­and react to fraudulently earned rewards by cancelling them and or suspending or terminating the Program or an individual’s right to participate.',
    zh_CN: '为求计划公平公正性，参与者若以恶意之电脑程序或其他明显违反计划公平性​​之方式，意图混淆或影响计划结果者，一经 ThunderCore 察觉，得立即取消参与者资格，并得追回奖励，且本计划执行单位将保留法律追诉权力。',
  },
  ruleCaution5_before: {
    en:    'If you are unable to log in SuperNode Staking for some reason, please contact ',
    zh_CN: '如在锁仓期间，因故无法登入计划网页，请来信至 ',
  },
  ruleCaution5_after: {
    en:    '. If you are unable to log in SuperNode Staking for some reason, please contact support@thundercore.com. However, in any eventuality where the data registered by the participant is lost, wrong, unrecognizable or damaged due to computer, network, technology or other reasons not attributable to ThunderCore, ThunderCore must not be held legally responsible, and participants must not object.',
    zh_CN: '。惟因电脑、网路、技术或其他不可归责于 ThunderCore 之事由，而使本计划参与者的资料有所遗失、错误、无法辨识或毁损所导致资料无效之情况，ThunderCore 不负任何法律责任，参与者亦不得异议。',
  },
  ruleCaution6: {
    en:    'ThunderCore will announce relevant information on this page of the SuperNode Staking service if there is any change to the Program, including our Terms and Conditions. When you participate in the Program, you are deemed to have agreed to our Terms and Conditions. Please read our Terms and Conditions carefully.',
    zh_CN: '本计划如有任何内容变更，包括我们的使用条款，ThunderCore将公布于本网页，恕不另行通知。您一旦参与本计划，即视为同意我们的使用条款。请仔细阅读我们的使用条款。',
  },
  ruleContactTitle: {
    en:    'Contact Us',
    zh_CN: '联络我们',
  },
  ruleContactInfo_before: {
    en:    'We welcome feedback, comments and suggestions for improvements to the Program. You can submit feedback by emailing us at ',
    zh_CN: '我们欢迎任何针对此计划的建议，欢迎来信：',
  },
  ruleContactInfo_email: {
    en:    'support@thundercore.com',
    zh_CN: 'support@thundercore.com',
  },
  ruleContactInfo_after: {
    en:    ' with the email subject "SuperNode Staking:"',
    zh_CN: '，并在信件标题注明 『SuperNode Staking: 』',
  },
  downloadHub: {
    en:    'Download ThunderCore Hub',
    zh_CN: '下载 TT 站',
  },
  downloadHubLink: {
    en:    'https://hub.thundercore.com/',
    zh_CN: 'https://hub.thundercore.com/',
  },
  autoRenewPayoutNote: {
    en:    'Attention: You have passed your withdraw date of the deposit, so the total payout will be recalculated after your withdrawal. The payout details is for reference only.',
    zh_CN: '注意：因您的锁仓已过提领日期，故实际可提币额将在您提币后重新计算，币额明细在此仅供参考。',
  },
  depositPerAddressLimit: {
    en:    'A single address can only have 16 active deposits in this service. To make another deposit, please create a new address/wallet and do it from there.',
    zh_CN: '同一地址在本服务中最多只能保持16笔进行中或到期的充币记录。若需充入更多笔到权益池请使用另一个地址/钱包',
  },
  depositeDisabledInfoHeader: {
    en:    'Deposit function closed',
    zh_CN: '存款功能中止',
  },
  depositeDisabledInfoBody: {
    en:    'The deposit function will closed on 2022/7/15. Deposit out of the lock-up period can be withdrawn freely and rewards will be still given out for those still in the lock-up period. The withdrawal function will be closed after the lock-up period is invalid and all deposit and rewards are withdrawn.',
    zh_CN: 'ThunderCore 决定关闭存款功能。存款功能將于 2022/7/15 关闭。除已锁仓的存款外，皆可以自由提领。锁定期到期，且所有存款和奖励都被提领后，提取功能将关闭。',
  }
}
